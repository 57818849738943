module.exports = {
  site: {
    name: 'Allocate',
    url: 'https://www.allocate.nz', // No trailing slash
    title: 'Allocate',
    description:
      'Allocate is a new platform for connecting creative freelancers with local businesses. Launching 2019.', // Used in the meta description
    keywords: '',
  },
  manifest: {
    shortName: 'Allocate', // Under 12 characters
    themeColor: '#9561e2',
    bgColor: '#9561e2',
    icon: 'icon.png', // Relative to the folder `./src/images/`
  },
  siteImage: 'site-image.jpg', // Relative to the folder `./static/`
  twitterHandle: 'batchnz',
  google: {
    analytics: {
      trackingId: 'UA-132466276-1',
    },
  },
}
